/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/uikit@3.6.18/dist/css/uikit.min.css
 * - /npm/uikit@3.6.18/dist/css/uikit.min.css
 * - /npm/uikit@3.6.18/dist/css/uikit-core.min.css
 * - /npm/uikit@3.6.18/dist/css/uikit-core.min.css
 * - /npm/uikit@3.6.18/dist/css/uikit-core-rtl.min.css
 * - /npm/uikit@3.6.18/dist/css/uikit-core-rtl.min.css
 * - /npm/uikit@3.6.18/dist/css/uikit-rtl.min.css
 * - /npm/uikit@3.6.18/dist/css/uikit-rtl.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
